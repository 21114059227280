import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import React from 'react';

import { CDNAssets } from '../CDN';
import { ContactUsMode } from '../components/forms/ContactForm';
import HeaderSection, { HeaderSectionDestinationUrl } from '../components/layout/sections/HeaderSection';
import PageSection from '../components/layout/sections/PageSection';
import { StrapiApiClient } from '../lib/integrations/strapi/StrapiApiClient';
import { StrapiApiArticle } from '../lib/integrations/strapi/types';
import { withGlobalDefaultStaticProps } from '../lib/next-hooks/withGlobalDefaultStaticProps';
import { getPageSeoConfig } from '../lib/seo/pageSeoConfigs';
import pageRoutes from '../router/pageRoutes';
import { NextPageWithLayout } from '../types/global-types';
import ContactUsWidget from '../widgets/interactive/ContactUsWidget';
import HowItWorksShowcase from '../widgets/showcases/HowItWorksShowcase';

// Dynamically import with SSR disabled
const LearnMoreShowcase = dynamic(() => import('../widgets/showcases/LearnMoreShowcase'), { ssr: false });
const SectionCoverParallax = dynamic(() => import('../components/interactive/SectionCoverParallax'), { ssr: false });

type FreeTrialSignupPageStaticProps = {
  articles: StrapiApiArticle[];
};

export const getStaticProps: GetStaticProps<FreeTrialSignupPageStaticProps> = async (context) => {
  const articles = await StrapiApiClient.getRandomMostRecentArticles();
  const props: FreeTrialSignupPageStaticProps = { articles };
  return withGlobalDefaultStaticProps(context, { props });
};

const FreeTrialSignupPage: NextPageWithLayout<FreeTrialSignupPageStaticProps> = (props) => {
  const { articles } = props;
  const seoConfig = getPageSeoConfig(pageRoutes.FreeTrial);
  return (
    <>
      <NextSeo {...seoConfig} />
      <HeaderSection
        imageUrl={CDNAssets.getUrl('free-trial-page-hero')}
        title="Free Trial"
        description="Automate employee support for free for 21 days - no credit card needed!"
        destinationUrl={HeaderSectionDestinationUrl.FreeTrial}
      />
      <PageSection isNarrowTop={true}>
        <SectionCoverParallax
          overlaySection={<ContactUsWidget defaultMode={ContactUsMode.FreeTrial} showAllModes={false} />}
          underlaySection={
            <HowItWorksShowcase description="Fill in the form below and experience the magic of Gaspar AI. Transform your Slack, MS Teams & JSM into a free AI helpdesk for 21 days." />
          }
        />
      </PageSection>
      <PageSection>
        <LearnMoreShowcase articles={articles} />
      </PageSection>
    </>
  );
};
FreeTrialSignupPage.displayName = 'FreeTrialSignupPage';
export default FreeTrialSignupPage;
