import classnames from 'classnames';
import React from 'react';

type HowItWorksShowcaseProps = {
  description: string;
};
const HowItWorksShowcase: React.FC<HowItWorksShowcaseProps> = React.memo((props) => {
  const { description } = props;
  return (
    <div className={classnames('text-center mt-[100px] md:mt-[0px] mb-[200px] md:mb-[300px]')}>
      <h1 className="mb-6">How it works?</h1>
      <p className="max-w-[500px] mx-auto text-center text-2xl">{description}</p>
    </div>
  );
});
HowItWorksShowcase.displayName = 'HowItWorksShowcase';
export default HowItWorksShowcase;
